


















import { Component, Vue } from "vue-property-decorator";
import { DefaTransitionFadeOutIn } from "@defa-as/components";
import RegistrationSuccessful from "@/components/registration/RegistrationSuccessful.vue";
import RegistrationForm from "@/components/registration/RegistrationForm.vue";
import { ROUTE_NAMES } from "@/router";

@Component({
  components: {
    DefaTransitionFadeOutIn,
    RegistrationSuccessful,
    RegistrationForm,
  },
})
export default class ViewRegistration extends Vue {
  showRegistrationSuccessful = false;
  registeredEmail = "";

  get loginRoute() {
    return {
      name: ROUTE_NAMES.LOGIN,
    };
  }

  onConfirmationSent({ email }: { email: string }) {
    this.showRegistrationSuccessful = true;
    this.registeredEmail = email;
  }
}
