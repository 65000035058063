
















































import type { AxiosError } from "axios";
import { Component, Vue } from "vue-property-decorator";
import { ValidationObserver } from "vee-validate";
import { DefaBackendErrorMessages, DefaBaseButton } from "@defa-as/components";
import { loadingModule } from "@/store/modules/loading-module";
import { userModule } from "@/store/modules/user-module";
import PasswordInput from "@/components/inputs/PasswordInput.vue";
import PasswordConfirmInput from "@/components/inputs/PasswordConfirmInput.vue";
import UsernameInput from "@/components/inputs/UsernameInput.vue";
import { Nullable } from "@defa-as/utils";

@Component({
  components: {
    UsernameInput,
    PasswordConfirmInput,
    PasswordInput,
    ValidationObserver,
    DefaBaseButton,
    DefaBackendErrorMessages,
  },
})
export default class RegistrationForm extends Vue {
  model = {
    email: "",
    password: "",
    passwordConfirmation: "",
  };
  error = {
    emailUsed: "",
    axiosError: null as Nullable<AxiosError>,
  };

  get loadingModule() {
    return loadingModule;
  }

  get isAuthorizationError() {
    return this.error.axiosError?.response?.status === 403;
  }

  get isUserModelError() {
    return this.error.axiosError?.response?.status === 400;
  }

  async register() {
    try {
      await userModule.register({
        email: this.model.email,
        password: this.model.password,
      });
      this.$emit("confirmation-sent", {
        email: this.model.email,
      });
    } catch (error) {
      this.error.axiosError = error;
      this.error.emailUsed = this.model.email;
    }
  }
}
