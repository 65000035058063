













import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class RegistrationSuccessful extends Vue {
  @Prop({ required: true }) registeredEmail!: string;
}
